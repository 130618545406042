<template lang="pug">
  form(@submit.prevent="onSubmit")
    .row
      .col-md-12
        .card
          .card-body
            .form-row
              .col-12
                .form-group
                  form-input(
                    id="name"
                    :model="$v.name"
                    :label="$t('form.name.label')"
                    :placeholder="$t('form.name.placeholder')"
                    :enabled="!submitting"
                  )



    .row(v-if="!session")
      .col-md-12
        button(type="submit" class="btn btn-lg btn-primary float-right" :disabled="submitting")
          span(v-if="!submitting")
            | {{ $t('form.submit.save') }}
            = ' '
            fa(icon="angle-right")

          span(v-if="submitting")
            button-spinner(color="#FFFFFF")
</template>

<script>
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import FormInput from '@/components/common/FormInput.vue'
import http from '@/http/client';
import _ from "lodash";

export default {
  components: {
    FormInput,
  },

  props: {
    activity: {
      type: [ String ],
      default: null
    }
  },

  data() {
    return {
      submitting: false,
      name: null
    }
  },

  validations: {
    name: {
      required,
      minLength: minLength(5),
      maxLength: maxLength(255)
    }
  },

  methods: {
    load() {
      if(!this.activity) {
        return;
      }

      this.lock();
      http
        .get(`/activities/${this.activity}`)
        .then((response) => _.assign(this, response.data))
        .finally(() => this.unlock())
    },

    lock() {
      this.submitting = true;
    },

    unlock() {
      this.submitting = false;
    },

    toggleOutliersAdvancedConfig() {
      this.filters.outliers.advanced = !this.filters.outliers.advanced;
    },

    onSubmit() {
      this.$v.$touch();

      if (this.$v.$anyError) {
        console.info('There is some issue in form. Please review it!', this.$v);
        return;
      }

      this.lock();

      http
        .post('/activities', { name: this.name, uuid: this.activity })
        .then(() => this.$router.push({ name: 'activities_list' }))
        .finally(() => this.unlock())
      ;
    }
  },

  mounted() {
    this.load();
  }
}
</script>

<i18n>
{
  "es": {
    "form": {
      "name": {
        "label": "Nombre de la actividad",
        "placeholder": "Nombre corto y descriptivo"
      },
      "submit": {
        "save": "Guardar"
      }
    }
  }
}
</i18n>
