<style lang="scss">
.clickable {
  cursor: pointer;
}
</style>

<template lang="pug">
  .card.card-solid
    .card-body.p-0
      .row
        .col-12
          table.table.table-hover.table-sm.m-0
            thead
              tr
                th {{ $t('table.header.name') }}
                th

            tbody(v-if="loading")
              td.text-center(colspan="6")
                .p-5
                  spinner(style="width: 3rem; height: 3rem;")

            tbody(v-else)
              tr(v-for="activity in activities" :class="{ 'text-muted': activity.$removing }")
                td.align-middle {{ activity.name }}

                td.align-middle
                  list-operations(
                    :show-remove="false"
                    @open="open(activity)"
                    @remove="remove(session)"
                  )
</template>

<script>
import _ from 'lodash';
import Duration from '@/components/common/Duration.vue'
import ListOperations from "@/components/common/ListOperations";
import http from '@/http/client';
import SessionState from "@/components/sessions/SessionState";

export default {
  components: {
    SessionState,
    Duration,
    ListOperations
  },

  data() {
    return {
      loading: false,
      activities: []
    }
  },

  methods: {
    load() {
      this.loading = true;
      return http
        .get('/activities')
        .then((response) => this.activities = response.data)
        .finally(() => this.loading = false)
      ;
    },

    add() {
      this.$router.push({ name: 'activities_create' })
    },

    open(activity) {
      this.$router.push({ name: 'activities_edit', params: { activity: activity.uuid }});
    },

    remove(session) {
      this.$swal
        .fire({
          title: this.$t('alert.remove.title', { name: session.name }),
          text: this.$t('alert.remove.warning'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: this.$t('alert.remove.delete')
        })
        .then((result) => {
          if (!result.isConfirmed) {
            return;
          }

          session.$removing = true;
          http.delete(`/sessions/${session.uuid}`)
            .then(() => {
              _.remove(this.sessions, session);

              this.$swal.fire(
                this.$t('alert.removeSuccess.title'),
                this.$t('alert.removeSuccess.warning', { name: session.name }),
                'success'
              );

              this.$forceUpdate();
            })
          ;

          this.$forceUpdate();
        });
    },

    isReady(session) {
      return 'ready' === session.state;
    },

  },

  mounted() {
    this.load();
  }
}
</script>

<i18n>
{
  "es": {
    "alert": {
      "remove": {
        "title": "¿Desea eliminar la sesión \"{name}\"?",
        "warning": "Esta acción es irreversible",
        "delete": "Eliminar"
      },
      "removeSuccess": {
        "title": "Sesión eliminada",
        "warning": "La sesión \"{name}\" se eliminó correctamente"
      }
    },
    "table": {
      "header": {
        "name": "Nombre"
      }
    }
  }
}
</i18n>
